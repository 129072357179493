import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AnchorsWrapper from "../components/anchorsWrapper"
import Breadcrumb from "../components/breadcrumb"

const IndexPage = () => (
  <Layout year="2022">
    <SEO
      path="/"
      year="2022"
    />

    <Breadcrumb label="Accueil" url="/" type="hp" />

    <AnchorsWrapper />
  </Layout>
)

export default IndexPage
